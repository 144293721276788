@import 'variables.scss';

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  overscroll-behavior: none;
  // min-height: 100vh;
  margin: 0;
  padding: 0;
  display: block;
  .default-font {
    font-family: Verdana, sans‑serif;
    word-spacing: -2px;
    letter-spacing: 0.15px;
  }
  font-size: 1em;
  line-height: 1.5;
  color: $text_black;
  font-size: 1rem;
  text-align: left;
}

#root {
  height: 100%;
}

.error-message {
  color: $text_grey;
  text-align: center;
}

main {
  margin: auto;
  padding: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    color: #5867dd;
    transition: all 100ms linear;
    &:hover, &:active, &:focus {
      color: #2739c1;
      text-decoration: underline;
    }
  }

  h1 {
    font-size: clamp(18px, 7vw, 1.7em);
  }
  h2 {
    font-size: clamp(18px, 6.5vw, 1.5em);
  }
  p {
    font-size: clamp(15px, 5vw, 1em);
    margin-bottom: 15px;
  }
  overflow-wrap: anywhere;
  word-break: normal;
}

.spinning-icon {
  animation: spin infinite 2s linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-as-link {
  border: 0;
  padding: 0;
  background: unset;
  text-decoration: underline;
  font-size: 0.9rem;
  color: $text_grey;
  font-family: inherit;

  &:hover, &:focus, &:active {
    color: $dark_text_black;
  }
}

.d-none {
  display: none;
}