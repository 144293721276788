@import '../variables.scss';

.field {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  margin-bottom: 20px;

  label {
    color: $text_black;
    font-size: clamp(17px, 6.25vw, 1.25em);
    margin-bottom: 5px;
  }

  &:has(.single-checkbox) {
    padding-top: 5px;
  }
}

.field, fieldset {
  label, legend {
    .optional-tag {
      padding-left: 10px;
      font-size: 0.85em;
      color: $light_grey;
      white-space: nowrap;
    }
  }
}


input[type="text"], input[type="email"], input[type="number"], input[type="tel"], textarea {
  border: 1px solid $lighter_grey;
  box-shadow: $input-box-shadow;
  border-radius: $border-radius;
  padding: 7px 10px;
  color: $text_black;
  transition: all .3s ease-in-out;
  font-size: 1.2em;
  line-height: normal;
  &:hover {
    border-color: $light_grey;
  }
}
.select-container input[type="text"] {
  box-shadow: none;
}

.select-container {
  .top-option.last-in-list { // NB. :last-of-type does not work with classes so we needed to add a reference to the last one in JS
    border-bottom: 1px solid #e6e8ef;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

textarea {
  font-family: inherit;
  resize: vertical;
}

$checkbox-size: 36px;
$checkbox-label-padding-left: 10px;

.single-checkbox, .checkbox-group {
  .checkbox-item {
    display: flex;
    position: relative;
    min-height: $checkbox-size;
    padding-left: $checkbox-size;

    input[type=checkbox] {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0px;
      width: $checkbox-size;
      height: $checkbox-size;
      margin: 0;
      opacity: 0;

      // When checked - colour the box
      &:checked+label:before {
        background-color: var(--accent-color);
        border-color: var(--accent-color);
      }
      // When checked - display the tick
      &:checked+label:after {
        opacity: 1;
      }

      &:focus+label:before {
          outline: 2px solid #0e5fcd;
      }
    }

    label {
      margin-bottom: auto;
      margin-top: auto;
      padding-left: $checkbox-label-padding-left;
      cursor: pointer;
      font-size: clamp(17px, 6.25vw, 20px); // We set the font-size as it impacts the absolute positioned tick

      // The box:
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: $checkbox-size;
        height: $checkbox-size;
        border: 1px solid $lighter_grey;
        box-shadow: $input-box-shadow;
        border-radius: $border-radius;
        transition: all .2s ease-in-out;
      }

      // The tick:
      &:after {
        content: "";
        position: absolute;
        top: 9px;
        left: 5px;
        width: 26px;
        height: 13px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border: solid;
        border-width: 0 0 4px 4px;
        opacity: 0;
        color: white;
      }
    }
  }

  .other-input-wrapper {
    margin-left: calc($checkbox-size + $checkbox-label-padding-left);
    margin-right: 26px;
  }
}

.form-card .single-checkbox {
  .question-hint {
    padding-left: calc($checkbox-size + $checkbox-label-padding-left);
    margin-top: 2.5px;
    margin-bottom: 0px;
  }

  .optional-tag-container {
    margin-bottom: auto;
    margin-top: auto;
    .optional-tag {
      padding-left: 10px;
      color: $light_grey;
      white-space: nowrap;
    }
  }
}

.checkbox-group {
  .checkbox-item {
    margin-bottom: 10px;
  }
}

.date-fieldset {
  .date-fields {
    display: inline-flex;

    .field {
      &:not(:last-of-type) {
        margin-right: 10px;
      }
      margin-bottom: 0px;

      label {
        font-size: clamp(16.5px, 5.5vw, 1.1em);
        margin-bottom: 5px;
        min-height: unset;
      }

      .year {
        width: 4em;
      }
      .month, .day {
        width: 2.9em;
      }
    }
  }
}

.date-fieldset, .radio-fieldset, .address-fieldset, .checkbox-group-fieldset {
  // Unset default fieldset style:
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;

  legend {
    display: table;
    padding: 0;
    margin-bottom: 5px;
    font-size: clamp(17px, 6.25vw, 1.25em);
  }
}

.radio-fieldset {
  .radio-button-group {
    padding-top: 5px;
  }

  $radio-size: 36px;
  $radio-label-padding-left: 10px;

  .radio-button-option {
    display: flex;

    position: relative;
    min-height: $radio-size;
    margin-bottom: 10px;
    &+.success-check-container {
      margin-bottom: 10px;
    }
    padding-left: $radio-size;

    input[type=radio] {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0px;
      width: $radio_size;
      height: $radio_size;
      margin: 0;
      opacity: 0;

      // When checked - colour the circle border
      &:checked+label:before {
        border-color: var(--accent-color);
      }
      // When checked - display the dot
      &:checked+label:after {
        opacity: 1;
      }

      &:focus+label:before {
        outline: 2px solid #0e5fcd;
      }
    }

    label {
      margin-bottom: auto;
      margin-top: auto;
      padding-left: $radio-label-padding-left;
      cursor: pointer;
      font-size: clamp(17px, 6.25vw, 20px); // We set the font-size as it impacts the absolute positioned dot

      // The outer circle:
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: $radio-size;
        height: $radio-size;
        border: 1px solid $lighter_grey;
        box-shadow: $input-box-shadow;
        border-radius: 50%;
        transition: border-color .2s ease-in-out;
      }

      // The inside dot:
      &:after {
        content: "";
        position: absolute;
        top: 9px;
        left: 9px;
        width: 0;
        height: 0;
        border: 9px solid var(--accent-color);
        border-radius: 50%;
        background: var(--accent-color);
        opacity: 0;
        transition: opacity .2s ease-in-out;
      }
    }
  }

  .other-input-wrapper {
    margin-left: calc($radio-size + $radio-label-padding-left);
    margin-right: 26px;
  }
}

.address-fieldset {
  label {
    font-size: clamp(16.5px, 6vw, 1.1em);
  }

  .field {
    margin-bottom: 10px;
  }
}

.form-card .question-hint {
  color: $light_grey;
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: clamp(15px, 5vw, 1em);
}

.input-success-wrapper {
  display: flex;

  input, textarea {
    flex-grow: 1; // Makes sure the inputs span the available width
    width: 100%;
  }

  .success-check-container {
    display: flex;
    align-items: center;
    width: 26px;
    padding-left: 10px;
  }

  .date-fields+.success-check-container {
    height: 34px;
    align-self: end;
  }
}

.field:not(.date-fields .field):not(.address-fields .field) {
  border-left: 3px solid transparent;
  padding-left: 10px;
  margin-left: -14px;
}

.invalid-feedback {
  display: none;
}

.lg {
  max-width: 100%;
}

.md {
  max-width: 250px
}

.sm {
  max-width: 150px
}

.xs {
  max-width: 70px;
}

input:valid:not(input.is-invalid):not(input[value=""]),
textarea:valid:not(textarea.is-invalid):not(:empty),
div[class$="control"]:has(+ input:valid) {
  border-color: #198573;
  transition: 200ms linear;
  transition-property: border-color;
}

input+.success-check-container svg,
textarea+.success-check-container svg,
.date-fields+.success-check-container svg,
.select-container+.success-check-container svg,
.radio-button-option+.success-check-container svg,
.checkbox-item+.success-check-container svg {
  display: none;
}

input.is-valid:valid+.success-check-container svg,
textarea.is-valid:valid+.success-check-container svg,
.select-container.select-is-valid+.success-check-container svg,
.date-fields.date-is-valid:has(input.year:valid):has(input.month:valid):has(input.day:valid)+.success-check-container svg,
.date-fields.date-is-valid:has(input.year:valid):not(input.month:valid):not(input.day:valid)+.success-check-container svg,
.date-fields.date-is-valid:has(input.year:valid):has(input.month:valid):not(input.day:valid)+.success-check-container svg {
  display: block;
  animation: inputFadeIn 200ms ease-in forwards;
}

// Firefox <121 will ignore the above block, so we need to repeat here
input.is-valid:valid+.success-check-container svg,
textarea.is-valid:valid+.success-check-container svg,
.select-container.select-is-valid+.success-check-container svg,
.date-fields.date-is-valid+.success-check-container svg {
  display: block;
  animation: inputFadeIn 200ms ease-in forwards;
}

.field:has(input.is-invalid:invalid),
.field:has(textarea.is-invalid:invalid),
.field:has(.select-is-invalid) {
  &:not(.date-fields .field):not(.address-fields .field) {
    border-left-color: $error_red;
    transition: 200ms linear;
    transition-property: border;
  }
}

input.is-invalid:invalid,
textarea.is-invalid:invalid,
div[class$="control"]:has(+ input.is-invalid:invalid) {
  border-color: $error_red;
  box-shadow: 0 0 2px 0px #dc354552, inset 0 0 2px 0px #dc354552;;
  transition: 200ms linear;
  transition-property: border-color;
}

.field:has(input.is-invalid:invalid) > .invalid-feedback,
.field:has(.select-is-invalid) > .invalid-feedback,
.field:has(textarea.is-invalid:invalid) > .invalid-feedback,
fieldset:has(input.is-invalid:invalid) > .invalid-feedback {
  display: block;
  margin: 0 0 5px 0;
  font-size: 0.95em;
  color: $error_red;
  animation: inputFadeIn 200ms ease-in forwards;
}

// Firefox <121 will ignore the above block, so we need to repeat here
.invalid-feedback.show {
  display: block;
  margin: 0 0 5px 0;
  font-size: 0.95em;
  color: $error_red;
  animation: inputFadeIn 200ms ease-in forwards;
}

.single-checkbox > .invalid-feedback {
  padding-left: calc($checkbox-size + $checkbox-label-padding-left);
}

.field:has(input.is-invalid:invalid) > .single-checkbox > .invalid-feedback {
  display: block;
  margin: 5px 0 0 0;
  font-size: 0.95em;
  color: $error_red;
  animation: inputFadeIn 200ms ease-in forwards;
}

@keyframes inputFadeIn {
  from { opacity: 0.2; }
  to { opacity: 1; }
}

.other-input-wrapper {
  display: flex;
}

.other-input {
  display: none;
  font-size: 1em;
  width: 100%;

  &.other-is-checked {
    display: block;
    animation: inputFadeIn 200ms ease-in forwards;
  }
}
