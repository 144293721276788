form {
  accent-color: var(--accent-color);

  .fields-container {
    padding-bottom: 2px;
  }

  .step {
    &.fade-in {
      animation: formFadeIn 400ms ease-in forwards;
    }
  }

  .title {
    margin: 20px 0;
  }
}

@keyframes formFadeIn {
  from { opacity: 0.2; }
  to { opacity: 1; }
}