@import '../variables.scss';

footer {
  margin-top: 20px;
  font-size: 0.8em;
  color: $lighter_grey;
  display: flex;
  justify-content: center;
  p {
    display: flex;
    align-items: center;
    font-size: 95%;
    margin: 0;
  }
  a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;

    img {
      margin-left: 4px;
      width: auto;
      height: 32px;
    }

    &:visited {
      color: $lighter_grey;
    }
    &:-webkit-any-link {
      color: $lighter_grey;
    }
  }
}
