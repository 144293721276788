@import 'variables.scss';

.completion {
  animation: fadeIn 400ms ease-in forwards;
  color: $text_grey;
  text-align: center;

  width: 45vw;
  max-width: 800px;
  min-width: 500px;
  padding: 20px 30px;

  @media only screen and (max-width: 500px){
    width: 100%;
    min-width: unset;
  }
}
