@import '../variables';

.cta-row {
  .submission-error {
    color: $error_red;
    text-align: center;
    margin: 0;
  }
}

.submit-btn, .continue-btn, .back-button {
  border-radius: 5px;
  font-family: inherit;
  line-height: normal;
  font-size: 1.1em;
  padding: 10px 15px;
  width: fit-content;
}

.submit-btn, .continue-btn {
  color: white;
  min-width: 100px;
  border: 1px solid #dfdfdf;
  background: linear-gradient(to top, #1a796a, #198573 15%);
  // NB. transition is not supported on gradients.

  &:hover, &:focus {
    background: var(--primary-button-hover-background) !important;
  }
  &:active {
    background: #18574E;
  }
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  &.first-step {
    justify-content: end;
  }
}

.back-button {
  color: $text_grey;
  border: 1px solid #6C6F7A;
  background:linear-gradient(to top, #dfdfdf, #FFFFFF 15%);

  &:hover, &:focus {
    background: linear-gradient(to top, #cccccc, #f5f5f6 15%);
  }
  &:active {
    background: #E5E6E8;
  }
}
