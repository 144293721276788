@import '../variables';

.form-card {
  background: white;
  width: 45vw; // Fixes the width better than %. NB. Stops the invalid feedback p tags from widening the area
  max-width: 800px;
  min-width: 500px;

  border-radius: $border-radius;
  padding: 20px 30px;

  @media only screen and (max-width: 500px){
    width: 100%;
    min-width: unset;
  }

  box-shadow: #dedede 0px 2px 8px 0px;

  h1 {
    color: var(--accent-color);
  }

  h2 {
    color: var(--accent-color);
  }

  p {
    margin-top: 0;
  }

  hr {
    margin: 1rem 0;
    border: 0;
    border-top: 1px solid #303136;
    opacity: 0.5;
  }
}
