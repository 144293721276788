.loading-form {
  width: 45vw; // Fixes the width better than %. NB. Stops the invalid feedback p tags from widening the area
  max-width: 800px;
  min-width: 500px;

  border-radius: 3px;
  padding: 20px 30px;

  @media only screen and (max-width: 500px){
    width: 100%;
    min-width: unset;
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  @keyframes skeleton-loading {
    0% {
      background-color: #f9fafb;
    }
    100% {

      background-color: #e1e7ea;
    }
  }
  .skeleton-header, .skeleton-label, .skeleton-hint, .skeleton-input {
    border-radius: 3px;
  }
  .skeleton-header {
    width: 100%;
    margin: 20px 0px;
    height: 2.2rem;
  }
  .skeleton-label, .skeleton-hint  {
    margin-bottom: 5px;
  }
  .skeleton-label {
    width: 50%;
    height: 1.5rem;
  }
  .skeleton-hint {
    width: 70%;
    height: 1.3rem;
  }
  .skeleton-input {
    width: 100%;
    height: 2.2rem;
    margin-bottom: 10px;
    border: 1px solid #d1dbe0;
  }
  .spinning-icon {
    margin: 30px 0px;
  }
}
